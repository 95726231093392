import { Heading } from 'components/Heading'
import { Card } from 'components/Card'
import styles from './ExternalResources.module.scss'

function ExternalResources() {
  return (
    <section className={styles.section}>
      <Heading hr>Resources</Heading>
      <p>
        The following links are listed to provide you with general mental health
        care information. The following is not meant to be a comprehensive list,
        or endorsement of the content of the sites.
      </p>
      <Card>
        <h3>Associations & Institutes</h3>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.counseling.org/"
            >
              American Counseling Association
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.apa.org/">
              American Psychological Association
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.nimh.nih.gov/"
            >
              National Institute of Mental Health
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.nmha.org/">
              National Mental Health Association
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.healthywomen.org/"
            >
              National Women&apos;s Health Resource Center
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.nami.org/">
              NAMI (National Alliance on Mental Illness)
            </a>
          </li>
        </ul>
      </Card>

      <Card>
        <h3>Suicide Awareness and Hotlines</h3>
        <ul>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.afsp.org/">
              American Foundation for Suicide Prevention
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://988lifeline.org/">
              988 Lifeline
            </a>
          </li>
        </ul>
      </Card>

      <Card>
        <h3>Depression</h3>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.apa.org/topics/depress/recover.aspx"
            >
              Depression and How Therapy Can Help
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.depressionscreen.org/"
            >
              Depression Screening
            </a>
          </li>
        </ul>
      </Card>

      <Card>
        <h3>Anxiety Disorders</h3>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ct.counseling.org/2017/05/living-with-anxiety/#respond"
            >
              Living with anxiety
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://screening.mhanational.org/screening-tools/anxiety/"
            >
              Anxiety Screening
            </a>
          </li>
        </ul>
      </Card>

      <Card>
        <h3>Posttraumatic Stress Disorder PTSD</h3>
        <ul>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.ptsd.va.gov/">
              National Center for PTSD
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="http://ptsdalliance.org/">
              PTSD Alliance
            </a>
          </li>
        </ul>
      </Card>

      <Card>
        <h3>Eye Movement Desensitization and Reprocessing EMDR</h3>
        <ul>
          <li>
            <a target="_blank" rel="noreferrer" href="http://emdria.org/">
              EMDR International Association
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.emdr.com/">
              EMDR Institute Inc
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://emdrfoundation.org/"
            >
              EMDR Foundation
            </a>
          </li>
        </ul>
      </Card>
    </section>
  )
}

export { ExternalResources }
