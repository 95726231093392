import styles from './Accordion.module.scss'
import { useId, useState } from 'react'
import { ChevronDown } from 'react-feather'
import { ChevronUp } from 'react-feather'

/**
 * 
 * @param {Array} items [{
      title:<></>,
      body: <></>,
    }] 
 * @returns 
 */
function Accordion({ items }) {
  const id = useId()
  const [radioState, setRadioState] = useState(
    items
      ? Object.fromEntries(
          items.map((_, index) => [`accordion-${id}-${index}`, false])
        )
      : {}
  )

  const onChangeRadio = (e) => {
    let radioStateTmp = { ...radioState }
    Object.keys(radioStateTmp).forEach((v) => (radioStateTmp[v] = false))
    setRadioState(() => {
      return {
        ...radioStateTmp,
        [e.target.id]: radioState[e.target.id] === true ? false : true,
      }
    })
  }

  const generateFAQs = (items) => {
    const arr = items.map((item, index) => {
      return (
        <div key={index}>
          <input
            type="radio"
            name={`accordion-${id}`}
            id={`accordion-${id}-${index}`}
            checked={radioState[`accordion-${id}-${index}`]}
            onChange={onChangeRadio}
            onClick={onChangeRadio}
          />

          <dt>
            <label htmlFor={`accordion-${id}-${index}`}>
              <span>{item.title}</span>
              {radioState[`accordion-${id}-${index}`] ? (
                <ChevronUp />
              ) : (
                <ChevronDown />
              )}
            </label>
          </dt>

          <dd>{item.body}</dd>
        </div>
      )
    })
    return <dl className={styles.dl}>{arr}</dl>
  }

  if (!items) return null
  return generateFAQs(items)
}

export { Accordion }
