/* eslint-disable @next/next/no-img-element */
import Link from 'next/link'
import Head from 'next/head'
import { LinkButton } from 'components/LinkButton'
import styles from './Hero.module.scss'

function Hero() {
  return (
    <>
      <Head>
        <link rel="preload" as="image" href="/img/hero.webp"></link>
      </Head>
      <section className={styles.section}>
        <div className={styles.hero}>
          <img
            src="/img/hero.webp"
            alt="Hero image of cactus and a canyon"
            // eslint-disable-next-line react/no-unknown-property
            fetchpriority="high"
          />
          <div className={styles.content}>
            <span>
              <h1>Your journey towards healing starts here</h1>
              <p>Online therapy for ADHD, Trauma, BPD, and Anxiety</p>
              <p>
                <small>
                  Serving all of Arizona, New Mexico, Florida, Vermont,
                  Michigan, Delaware, and Pennsylvania
                </small>
              </p>
            </span>
            <Link href="/contact" passHref>
              <LinkButton>Book a No-Cost Consultation</LinkButton>
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export { Hero }
