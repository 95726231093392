import { Heading } from 'components/Heading'
import { Card } from 'components/Card'
import styles from './TypesOfTherapyOffered.module.scss'

function TypesOfTherapyOffered() {
  return (
    <section className={styles.section}>
      <Heading>Types of Therapy Offered</Heading>
      <div className={styles.grid}>
        <Card color="blue" pipe>
          <h3>EMDR</h3>
          <p>
            EMDR (Eye Movement Desensitization and Reprocessing) can help you
            heal from emotional distress and symptoms resulting from a traumatic
            or disturbing experience.
          </p>
          <p>
            EMDR targets memories and feelings the body and brain struggle to
            process on its own.
          </p>
          <p>
            Through EMDR bilateral stimulation, the meaning of these traumatic
            or disturbing events can be changed and adapted to allow for
            healing.
          </p>
        </Card>
        <Card color="blue" pipe>
          <h3>CBT</h3>
          <p>
            Cognitive Behavior Therapy (CBT) can help you understand the
            connection between thoughts, emotions, and behavioral responses.
          </p>
          <p>
            Through CBT you&apos;ll learn how to identify, challenge, and
            interrupt the untrue or maladaptive ways of thinking to reduce
            anxiety, depression, and trauma symptoms.
          </p>
        </Card>
        <Card color="blue" pipe>
          <h3>TF-CBT</h3>
          <p>
            Trauma Focused Cognitive Behavior Therapy is a short term,
            structured therapy to help children and teens recover after trauma.
          </p>
          <p>
            TF-CBT is effective at improving youth posttraumatic stress disorder
            (PTSD) symptoms, depression, as well as child and caregiver
            distress.
          </p>
          <p>
            TF-CBT can help you learn skills to decrease symptoms after
            traumatic experiences.
          </p>
        </Card>
        <Card color="blue" pipe>
          <h3>DBT</h3>
          <p>
            DBT or Dialectical Behavior Therapy can help you learn how to
            regulate emotions, tolerate distress, improve your relationships,
            and be more present in your life.
          </p>
          <p>
            DBT was originally created to treat Borderline Personality Disorder,
            but can also improve symptoms of anxiety, depression, and trauma.
          </p>
          <p>
            DBT provides a structured format that emphasizes skill-building,
            collaboration, and acceptance.
          </p>
        </Card>
      </div>
    </section>
  )
}

export { TypesOfTherapyOffered }
