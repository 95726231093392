import NextLink from 'next/link'
import { FAQ as FAQs } from 'components/FAQ'
import { Heading } from 'components/Heading'
import styles from './FAQ.module.scss'

const faqs = [
  {
    title: <h3>How does therapy work?</h3>,
    body: (
      <p>
        Therapy is a place to explore your feelings and experiences. I listen
        without judgement, ask questions, and guide you through self-reflection.
        You will receive tools to help you make different choices and develop
        healthier relationships. By actively engaging and reflecting on your
        thoughts, feelings, and experiences in the safety of a supportive
        environment, you can make significant changes in your everyday
        functioning and quality of life. Together we&apos;ll discover
        what&apos;s standing in the way of your happiness and reach your goals.
      </p>
    ),
  },
  {
    title: <h3>What issues do you treat?</h3>,
    body: (
      <p>
        Sunridge Counseling helps individuals with Trauma, Anxiety, Depression,
        ADHD, premartial concerns and general life stressors.
      </p>
    ),
  },
  {
    title: <h3>How much will services cost?</h3>,
    body: (
      <p>
        Therapy Services cost $150 per 50-minute session and $270 per 90-minute
        session. Payment is due at the time of service and a card must be on
        file before the first appointment. You will receive a Good Faith
        Estimate to help you understand your financial responsibility as part of
        the intake paperwork.
      </p>
    ),
  },
  {
    title: <h3>What is a Good Faith Estimate (GFE)? </h3>,
    body: (
      <>
        <p>
          The No Surprises Act Legislation (2799B-6 of the Public Health Service
          Act) became effective 1/1/2022. This Legislation requires health care
          providers to provide a Good Faith Estimate for Services, specifically
          for private pay clients who are not using insurance, have opted out of
          using insurance, or who have insurance that the provider does not
          participate with.
        </p>
        <p>
          You have the right to receive a GFE explaining how much your medical
          care will cost. Please note that this GFE also does not include any
          additional fees for paperwork for legal matters or court
          appearances/subpoenas. Please contact our office to learn more about
          our rates for services.
        </p>
      </>
    ),
  },
  {
    title: <h3>Can I use my health insurance?</h3>,
    body: (
      <p>
        Sunridge Counseling does not accept health insurance. However, we can
        provide you with a superbill. A superbill is a document that contains
        information your insurance company needs to reimburse you for services
        directly. The information includes the name of your therapist, our
        office address, national provider ID number (NPI), business tax ID
        number, mental health diagnosis, dates of service, procedure codes, and
        the cost paid. Sunridge Counseling does not interact with or guarantee
        reimbursement from your insurance provider.
      </p>
    ),
  },
  {
    title: <h3>Are there benefits to not using health insurance?</h3>,
    body: (
      <>
        <p>There can be many benefits to not using health insurance:</p>
        <ol>
          <li>
            <p>
              You are in control of your care, including choosing your
              therapist, length of treatment, etc.
            </p>
          </li>
          <li>
            <p>
              Increased privacy and confidentiality (except for limits of
              confidentiality).
            </p>
          </li>
          <li>
            <p>
              Not having a mental health disorder diagnosis on your medical
              record. (If you use a superbill, you must have a diagnosis)
            </p>
          </li>
          <li>
            <p>
              Consulting with me on non-psychiatric issues that are important to
              you that aren&apos;t billable by insurance, such as learning how
              to cope with life changes, gaining more effective communication
              techniques for your relationships, increasing personal insight,
              and developing healthy new skills.
            </p>
          </li>
        </ol>
        <p>
          After reading my position on why I don&apos;t accept health insurance,
          you still may decide to use your health insurance. If you provide me
          with a list of therapists on your insurance provider list, I will do
          my best to recommend a therapist for you.
        </p>
      </>
    ),
  },
  {
    title: <h3>How do I contact you?</h3>,
    body: (
      <p>
        You can fill out the contact form on the website by{' '}
        <NextLink href="/contact">
          <a>clicking here.</a>
        </NextLink>
      </p>
    ),
  },
]

function FAQ() {
  return (
    <section id="faq" className={styles.section}>
      <Heading hr>FAQ</Heading>
      <FAQs FAQs={faqs} />
    </section>
  )
}

export { FAQ }
