import styles from './HowItWorks.module.scss'

function HowItWorks() {
  return (
    <section className={styles.section}>
      <h2>How it Works</h2>
    </section>
  )
}

export { HowItWorks }
