import Link from 'next/link'
import { Heading } from 'components/Heading'
import { Accordion } from 'components/Accordion'
import styles from './CommonIssuesTreated.module.scss'

function CommonIssuesTreated() {
  const faqs = [
    {
      title: <h3>Anxiety Treatment</h3>,
      body: (
        <>
          <p>
            Are you constantly worrying, on edge, or find yourself irritable and
            short with those around you? Maybe you have racing thoughts make it
            hard to concentrate and be in the moment.
          </p>
          <p>
            Anxiety is a normal response to stress, change, and uncertainty.
            Everyone experiences some level of anxiety at one time or another.
            However, anxiety can become overwhelming and feel out of proportion
            to the situation.
          </p>
          <p>
            If you find that anxiety is interfering with your life, making it
            difficult to engage in activities you use to love, and causing
            intense physical symptoms like panic, it&apos;s important to seek
            help. With the right support, you can regain control over your life.
          </p>
          <p>
            You deserve to live a life where anxiety doesn&apos;t hold you back,
            and with the right help, you can find ways to cope with and overcome
            anxiety. We will not only work on the here and now symptoms of your
            anxiety, but also the root causes to help you take control over your
            emotions. You don&apos;t have to face this alone and there is hope
            for a brighter, less anxious future.
          </p>
        </>
      ),
    },
    {
      title: <h3>Trauma and PTSD Treatment</h3>,
      body: (
        <>
          <p>
            Do you notice a shift in the way you view yourself or the world
            around you? Do certain memories or thoughts from the past keep
            intruding in your mind? Maybe you actively avoid certain things
            because they bring back painful memories.
          </p>
          <p>
            Trauma is the result of distressing and unsettling experiences that
            have happened in your life. When we think of trauma, we often
            picture physical, emotional, or sexual abuse, natural disasters,
            loss, and war. It is important to understand that trauma can stem
            from anything that was overwhelming to our system, such as emotional
            neglect, bullying, poverty, disabilities, the list goes on.
          </p>
          <p>
            Trauma leaves a profound impact on every aspect of our lives. Trauma
            can make us feel on edge, leading to nightmares, heightened
            awareness of your surroundings, and a persistent sense of guilt,
            shame, or irritability. Your relationships with others can also be
            affected, and it might leave you feeling trapped in every aspect of
            your life.
          </p>
          <p>
            You deserve to live a life where trauma doesn&apos;t hinder your
            progress. With the right support, you can discover ways to navigate
            and overcome the impact of your trauma. Our journey won&apos;t just
            focus on addressing the immediate effects of your trauma; we&apos;ll
            also explore your past, internalized beliefs, and patterns to
            empower you with emotional control and healing.
          </p>
        </>
      ),
    },
    {
      title: <h3>ADHD and Neurodivergent Treatment</h3>,
      body: (
        <>
          <p>
            Do you find it challenging to stay focused, maybe you easily get
            sidetracked and feel like you can&apos;t catch up. Are you wrestling
            with time management, feeling bound by expectations, or experiencing
            low tolerance for frustration? Neurodivergent symptoms can even make
            it difficult to regulate your emotions, especially when you&apos;re
            overwhelmed by sensory input.
          </p>
          <p>
            The world of ADHD and Autism is not straightforward, and for women,
            it can be especially complex. The characteristics of ADHD and Autism
            often present differently in women compared to men, which can lead
            to misdiagnosis or even having your symptoms overlooked. It is
            essential to acknowledge that neurodivergence doesn&apos;t adhere to
            stereotypes and it has a wide range of expressions.
          </p>
          <p>
            As you explore the possibility of being neurodivergent, it&apos;s
            important to remember that this journey isn&apos;t about fitting
            into a predefined mold. It&apos;s about understanding and embracing
            your unique cognitive profile. Discovering your neurodivergent
            identity can be a powerful and transformative experience, unveiling
            hidden strengths and perspectives that make you, well, you.
          </p>
          <p>
            Neurodivergent therapy looks different for each person and we will
            most often start by exploring the predefined judgements and masking
            behaviors you&apos;ve had to adpot to survive and fit in this far.
          </p>
        </>
      ),
    },
    {
      title: <h3>Borderline Personality Disorder Treatment</h3>,
      body: (
        <>
          <p>
            Is it a constant struggle to manage your emotions and maintain
            stability in work, relationships, and life? Do you find yourself in
            a never-ending battle with intense highs and lows, often triggered
            by the world around you? Borderline Personality Disorder or BPD can
            make life feel overwhelming and isolating.
          </p>
          <p>
            Navigating the world of BPD can be a complex journey. Your
            experience might not always align with the stereotypes or
            expectations of BPD. You may have encountered misunderstandings or
            even faced the misinterpretation of your symptoms. It&apos;s
            important to recognize that BPD is a highly individualized
            experience, and it doesn&apos;t fit into a one-size-fits-all mold.
          </p>
          <p>
            In our exploration of your BPD symptoms, remember that this path is
            about understanding and embracing your unique emotional landscape.
            It&apos;s an opportunity to gain insight into your world and develop
            coping strategies that are tailored to your specific needs.
            Discovering your BPD identity is not a limitation; it&apos;s a
            powerful journey that can unveil your resilience, creativity, and
            your authentic self.
          </p>
          <p>
            BPD therapy is a collaborative and customized process. We often
            begin by exploring the judgments and coping mechanisms you&apos;ve
            adopted to navigate life so far. Our work together will focus on
            understanding and reprocessing any trauma you have experienced,
            building emotional stability, managing healthier relationships, and
            developing a stronger sense of self.
          </p>
          <p>
            Borderline Personality Disorder doesn&apos;t define you; it&apos;s
            just one facet of your identity at the moment. My aim is to support
            you in navigating the complexities of BPD and empowering you to lead
            a fulfilling and emotionally balanced life. You&apos;re not alone on
            this journey, and there&apos;s hope for a brighter and more
            emotionally stable future.
          </p>
        </>
      ),
    },
    {
      title: <h3>Wedding Focused Therapy</h3>,
      body: (
        <>
          <p>
            Has wedding planning increased your anxiety, stress levels, and
            relationship conflict? Are you struggling to find balance and
            enjoyment in the wedding planning process? Sunridge Counseling
            offers wedding focused therapy, coaching, and premarital counseling.
          </p>
          <p>
            Wedding Focused Therapy can help you be present in the moment,
            manage anxiety, set boundaries, and process your emotions so you can
            enjoy your big day and all the ones ahead.
          </p>
          <p>
            Coaching is for people recognize their wedding is adding stress to
            their lives, but may not want traditional therapy. We will set goals
            and create action plans to achieve those goals.
          </p>
          <p>
            Premarital Counseling helps couples prepare for marriage by
            addressing any issues or concerns that may arise before the wedding.
            It can help couples develop communication skills, resolve conflicts,
            and establish a strong foundation for their future together.
          </p>
          <p>
            <Link href="/weddings">
              <a>Learn more about Wedding Focused Therapy</a>
            </Link>
          </p>
        </>
      ),
    },
  ]
  return (
    <section className={styles.section}>
      <Heading>Common Issues Treated</Heading>
      <Accordion items={faqs} />
    </section>
  )
}

export { CommonIssuesTreated }
