/* eslint-disable @next/next/no-img-element */
import { Heading } from 'components/Heading'
import styles from './TenasBio.module.scss'

function TenasBio() {
  return (
    <section className={styles.section} href="#about-tena">
      <Heading hr>Meet Tena</Heading>
      <img
        srcSet="/img/tena-bio-350w.webp 640w, /img/tena-bio-450w.webp 450w"
        sizes="(max-width: 640px) 640px, 450px"
        src="/img/tena-bio-450w.webp"
        alt="Picture of Tina standing and smiling"
        loading="lazy"
      />
      <div className={styles.text}>
        <p>
          I&apos;m Tena, a licensed counselor in Arizona, New Mexico,
          Pennsylvania, Florida, Michigan, and Vermont. I started Sunridge
          Counseling in 2022 and have been providing therapy since 2016.
        </p>

        <p>
          I specialize in helping teens and adults navigate the challenges of
          BPD, ADHD, Anxiety, and Trauma. I provide therapy tailored to your
          needs and draw from DBT, IFS, and EMDR. Let&apos;s work together to
          embrace a life full of stability and emotional well-being.
        </p>

        <p>
          It can be difficult to keep moving, especially when you have frequent
          symptoms. In therapy, we will dive deep to uncover the messages you
          have absorbed from other people and find those lightbulb moments of
          insight and understanding. We will laugh, cry, and learn together, all
          while guiding you towards healing.
        </p>

        <p>
          What if you could not only manage your symptoms, but experience much
          less of them? Imagine if you could understand yourself better, feel
          comfortable and secure in your relationships, and create a meaningful
          life beyond a diagnosis. When you&apos;re ready to take that step
          forward, contact me to schedule a free 15-minute consultation!
        </p>
      </div>
    </section>
  )
}

export { TenasBio }
