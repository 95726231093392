import styles from './Card.module.scss'

function Card({ color, pipe, children }) {
  return (
    <div
      className={`${styles.card} ${color && styles.border} ${
        color && styles[color]
      }`}
    >
      <div className={styles.topBorder}></div>
      <div className={`${styles.contents} ${pipe && styles.pipe}`}>
        {children}
      </div>
    </div>
  )
}

export { Card }
