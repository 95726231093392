import { Button } from 'components/Button'
import styles from './ContactForm.module.scss'

function ContactForm() {
  return (
    <form
      name="contact"
      action="/success"
      method="POST"
      data-netlify="true"
      // eslint-disable-next-line react/no-unknown-property
      netlify-honeypot="bot-field"
      className={styles.form}
    >
      <input type="hidden" name="form-name" value="contact" />
      <span hidden>
        <label>
          Do not fill this out if you are human: <input name="bot-field" />
        </label>
      </span>
      <div>
        <label htmlFor="yourname">First and Last Name</label>
        <input type="text" name="name" id="yourname" required />
      </div>
      <div>
        <label htmlFor="youremail">Email</label>
        <input type="email" name="email" id="youremail" required />
      </div>
      <div>
        <label htmlFor="yourmessage">Message</label>
        <textarea name="message" id="yourmessage" rows={4} required></textarea>
      </div>
      <div>
        <Button type="submit">Send</Button>
      </div>
    </form>
  )
}

export { ContactForm }
