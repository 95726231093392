import styles from './Heading.module.scss'

function Heading({ hr, color, children }) {
  return (
    <div className={styles.heading}>
      <h2 className={`${!hr && styles.hrVisible}`}>{children}</h2>
      <hr className={`${hr && styles.hrVisible} ${color && styles[color]}`} />
    </div>
  )
}

export { Heading }
