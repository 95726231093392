import { InlineWidget } from 'react-calendly'
import { ContactForm } from 'components/ContactForm'
import { Heading } from 'components/Heading'
import styles from './WaitListForm.module.scss'

function WaitListForm() {
  return (
    <section className={styles.section} id="contact">
      <div className={styles.container}>
        <div className={styles.heading}>
          <Heading hr>Contact Sunridge Counseling</Heading>
        </div>
        <div className={styles.body}>
          <div>
            <p>
              To get started and book your no-cost consultation, use the email
              or the Calendly form.
            </p>
          </div>
          <div className={styles.forms}>
            <div>
              <InlineWidget
                url="https://calendly.com/tenathomaslpc/15min?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=0372e7"
                styles={{
                  minWidth: '320px',
                  height: '530px',
                }}
              />
            </div>
            <div>- OR -</div>
            <div>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { WaitListForm }
