import { Accordion } from 'components/Accordion'
import Head from 'next/head'
import reactToText from 'react-to-text'

function FAQ({ FAQs, ...props }) {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: FAQs.map((faq) => ({
      '@type': 'Question',
      name: reactToText(faq.title),
      acceptedAnswer: {
        '@type': 'Answer',
        text: reactToText(faq.body),
      },
    })),
  }

  function JSONLD(schema) {
    return (
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            //__html: JSON.stringify(schema, null, '\t'),
            __html: JSON.stringify(schema),
          }}
        ></script>
      </Head>
    )
  }

  return (
    <>
      <JSONLD schema={schema} />
      <Accordion items={FAQs} {...props} />
    </>
  )
}

export { FAQ }
