import styles from './Button.module.scss'

function Button({ children, color, ...props }) {
  return (
    <button className={`${styles.button} ${color && styles[color]}`} {...props}>
      {children}
    </button>
  )
}

export { Button }
